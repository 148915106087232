import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation, Autoplay } from 'swiper/modules';
import '../assets/AMozak/AMozak.sass'

import img1 from '../assets/AMozak/01.jpg'
import img2 from '../assets/AMozak/02.jpg'
import img3 from '../assets/AMozak/03.jpg'
import img4 from '../assets/AMozak/04.jpg'
import img5 from '../assets/AMozak/05.jpg'
import img6 from '../assets/AMozak/06.jpg'

import Mozak30Anos from '../assets/AMozak/30AnosMozak.png'

function Carosel() {
    return (
        <Swiper navigation={true} modules={[Navigation, Autoplay]} autoplay={{ delay: 3000 }} loop>
            <SwiperSlide key={1}>
                <img src={img1} alt='Imagem 1' />
            </SwiperSlide>
            <SwiperSlide key={2}>
                <img src={img2} alt='Imagem 2' />
            </SwiperSlide>
            <SwiperSlide key={3}>
                <img src={img3} alt='Imagem 3' />
            </SwiperSlide>
            <SwiperSlide key={4}>
                <img src={img4} alt='Imagem 4' />
            </SwiperSlide>
            <SwiperSlide key={5}>
                <img src={img5} alt='Imagem 5' />
            </SwiperSlide>
            <SwiperSlide key={6}>
                <img src={img6} alt='Imagem 6' />
            </SwiperSlide>
        </Swiper>
    )
}


function Content() {

    return (
        <div className='content'>
            <img className='comemorativa' src={Mozak30Anos} alt='Mozak 30 Anos' />
            <h1>Sobre nós</h1>
            <p>Há três décadas assumimos o compromisso de
                construir imóveis exclusivos e sofisticados para
                melhorar o conceito de viver e hoje seguimos fiéis
                ao nosso propósito.
            </p>
            <p>
                Com atendimento personalizado, unimos
                exclusividade, design autoral e arquitetura
                assinada, sempre pensando em quem busca
                melhor viver e investir na zona sul do Rio.
            </p>
            <p>
                Mozak – a arte de viver o Rio em toda a sua essência
            </p>
            <a href='https://mozak.rio/negocio/' target='_blank' rel='noreferrer'>Saiba mais sobre a Mozak</a>
        </div>
    )
}

function AMozak() {
    return (
        <section id='timeline-mozak'>
            <Carosel />
            <Content />
        </section>
    );
}

export default AMozak;