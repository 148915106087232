import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import DocumentMeta from "react-document-meta";

const root = ReactDOM.createRoot(document.getElementById('root'));

const meta = {
  title: 'Acervo - Mozak',
  description: 'Acervo - Mozak',
  // canonical: 'http://example.com/path/to/page',
  meta: {
    title: 'Acervo - Mozak',
    charset: 'utf-8',
    name: {
      keywords: 'mozak,leblon,empreendimento,lançamento,comercial,luxo,imobiliária,imóveis,imobiliárias,imobiliárias no rio de janeiro,imóveis no rio de janeiro,imóveis no rio,imóvel'
    }
  }
};

root.render(
  <React.StrictMode>
    <DocumentMeta {...meta} >
      <App />
    </DocumentMeta>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
