import '../assets/styles/components/Button.sass'

export default function Button(){
  
  const handleClick = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }

  return(
    <button className="contact" onClick={handleClick}>Contato</button>
  );
}