import React from 'react';
import '../assets/styles/components/Gallery.sass';
import ImageGallery from 'react-image-gallery';
import { useEffect, useState } from 'react';
import CloseIcon from '../assets/images/close.png'

export default function Gallery({images}){
  const [galleryImages, setGalleryImages] = useState([]);
  
  const fetchGalleryImages  = () =>{
    const data = [];
    images.map((item, index) => {
      data.push({
        original: `/${item}`,
        thumbnail: `/${item}`
      })
    })

    setGalleryImages(data);
    return data;
  }

  useEffect(() => {
    fetchGalleryImages();
  }, [])

  const closeGallery = () => {
    document.querySelector('.gallery__component').style.display = 'none';
    document.querySelector('.gallery__background').style.display = 'none';
  }


  return (
    <>
      <div className="gallery__background" onClick={closeGallery}></div>
      <div className="gallery__component">
        <img className="close__button" src={CloseIcon} onClick={closeGallery} alt="Fechar" />
        <ImageGallery items={galleryImages} originalWidth={200} showFullscreenButton={false} />
      </div>
    </>
  );
}