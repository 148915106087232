import React, { useEffect, useState, useRef } from 'react';

import { getLocalData, getBairros } from '../lib/localdata.jsx'
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import gsap from 'gsap';
import { useGSAP } from '@gsap/react';


function Bairros() {

    const bairros = getBairros()

    function scrollToLocation(bairroId) {
        const locationElement = document.getElementById(bairroId);
        if (locationElement) {
            locationElement.scrollIntoView({ behavior: "smooth" });
        }
    }

    function deactiveCard() {
        const cardElements = document.getElementsByClassName('card-bairro');
        for (let i = 0; i < cardElements.length; i++) {
            cardElements[i].setAttribute('data-active', 'none');
        }
    }

    function activeCard(target) {
        const cardElements = document.getElementsByClassName('card-bairro');
        for (let i = 0; i < cardElements.length; i++) {
            cardElements[i].setAttribute('data-active', 'deactive');
        }
        target.setAttribute('data-active', 'active');
    }

    const frases = [
        'morar',
        'trabalhar',
        'investir'
    ]

    const [activeFrase, setActiveFrase] = useState(0)

    const splitStringToSpans = (str) => {
        return str.split('').map((char, index) => (
            <span className='overflow-hidden' key={index}>{char}</span>
        ));
    };

    const container = useRef(null)

    gsap.registerPlugin(useGSAP);

    useEffect(() => {
        gsap.fromTo('#frases > h1', {
            width: 0
        }, {
            ease: `steps(${frases[activeFrase].length})`,
            width: '100%',
            duration: 1,
            stagger: .2,
            onComplete: () => {
                gsap.delayedCall(2, () => {
                    setActiveFrase(prevFrase => (prevFrase + 1) % frases.length);
                });
            }
        })

        gsap.fromTo('#frases > span', {
            opacity: 0,
        }, {
            opacity: 100,
            ease: 'steps(1)',
            duration: .8,
            repeat: -1
        })

    }, [activeFrase, frases.length])



    return (
        <section ref={container} id="bairros" className='py-10'>
            <div className='w-full flex flex-col sm:flex-row justify-center items-center mb-10'>
                <div className='flex justify-end'>
                    <h1 className='text-4xl font-normal'>Escolha onde você quer</h1>
                </div>
                <div id='frases' className='flex'>
                    <h1 className='overflow-hidden text-4xl font-normal ml-3'>{splitStringToSpans(frases[activeFrase])}</h1>
                    <span className='overflow-hidden text-4xl font-normal'>_</span>
                </div>
            </div>
            <div className='gap-4 px-20 justify-center w-auto h-[90vh] 2xl:h-[55vh] hidden sm:flex'>
                {bairros.map((bairro, i) => (
                    <div key={i} data-active='none' className='card-bairro parent w-[20%] flex flex-col justify-between cursor-pointer transition-all
                        no-underline
                        duration-500
                        data-[active=active]:brightness-110
                        data-[active=active]:w-[30%]

                        data-[active=deactive]:brightness-75
                        data-[active=deactive]:opacity-70
                    '
                        onMouseEnter={(element) => { activeCard(element.currentTarget) }}
                        onMouseLeave={() => deactiveCard()}
                        onClick={() => scrollToLocation(bairro.bairro)}>
                        <img className='h-[300px] w-full object-cover transition-all' src={bairro.image} alt='' />
                        <h2 className='text-lg font-semibold mt-4 mb-0'>{bairro.bairro}</h2>
                        <p>{bairro.desc_curta}</p>
                        <p>{bairro.metragem}</p>
                        <button className='w-full text-center font-semibold rounded-md py-2 border-[1px]
                        border-black cursor-pointer transition-all
                            parent-active:bg-black parent-active:text-white
                            parent-deactive:bg-transparent parent-deactive:text-black
                        '>Ver mais</button>
                    </div>
                ))}
            </div>
            <div className='flex gap-4 h-[70%] justify-center sm:hidden'>
                <Swiper slidesPerView={1} centeredSlides navigation={true} modules={[Navigation]}
                    className='
                        [&>.swiper-button-prev]:translate-y-[-150%]
                        [&>.swiper-button-prev]:text-black
                        [&>.swiper-button-next]:translate-y-[-150%]
                        [&>.swiper-button-next]:text-black
                    '
                >
                    {bairros.map((bairro, i) => (
                        <SwiperSlide key={i}
                            className='
                                !grid
                                place-items-center
                            '
                        >
                            <div className='w-[75%] flex flex-col justify-around cursor-pointer transition-all no-underline text-black' onClick={() => scrollToLocation(bairro.bairro)}>
                                <img src={bairro.image} alt='' className='h-[70%] w-full object-cover transition-all' />
                                <h2 className='text-2xl font-semibold mt-4 mb-0 text-center'>{bairro.bairro}</h2>
                                <p className='mt-4 text-center'>{bairro.desc_curta}</p>
                                <p className='mt-4 text-center'>{bairro.metragem}</p>
                                <button className='w-full rounded-lg border-[1px] border-black cursor-pointer transition-all py-2 mt-4'>Ver mais</button>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section >
    );
}

export default Bairros;