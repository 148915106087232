import '../assets/styles/project/Project.sass';
import Point from '../assets/images/point.png';
import Arch from '../assets/images/artis_arch.png';
import Nav from '../components/Nav';
import Divider from '../components/Divider';
import Footer from '../components/Footer';
import Datasheet from '../assets/images/artis_datasheet.png';
import { getAllProjects, getProject } from '../lib/localdata';
import Button from '../components/Button';
import GalleryIcon from '../assets/images/gallery_icon.png';
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Gallery from '../components/Gallery'
import AMozak from '../components/AMozak';



export default function Project() {
  const { slug } = useParams();
  const [project, setProject] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const project = getProject(slug);
    setProject(project.project);
    setIsLoading(false);
  }, [slug]);

  const openGallery = () => {
    document.querySelector('.gallery__component').style.display = 'flex';
    document.querySelector('.gallery__background').style.display = 'flex';
  }


  return (
    <>
      {!isLoading &&
        <>
          <Nav />
          <div className="project">

            <section className="details">
              <div className="details__image">
                <img
                  src={`/${project.hero}`}
                  alt="image"
                />
              </div>

              <div className="details__info">
                <h1>{project.title}</h1>
                <h2>{project.subtitle}</h2>
                <ul>
                  {project.tags.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>

                <div className="details__address">
                  <img src={Point} alt="image" />
                  <p>{project.address}</p>
                </div>
                <Button />
              </div>
            </section>

            <section className="description">
              <p>{project.about}</p>
            </section>

            <section className="video">
              {project.video == "" ?
                <img src={`/${project.video_image}`} alt="image" />
                :
                project.video.includes("youtube") ?
                  <iframe width="1425" height="621" src={project.video} title={project.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  :
                  <video controls>
                    <source src="../Essencia/Largo do Piva_Mozak_reduzido.mp4" type="video/mp4" />
                    Seu navegador não suporta o elemento de vídeo.
                  </video>
              }

              <Button />
            </section>

            <Divider />

            <section className="about">
              <h1>O PROJETO</h1>
              <p>{project.aboutProject}</p>
            </section>

            <section className="gallery">
              <div className="gallery__images">
                {project.gallery.slice(0, 4).map((item, index) => (
                  <div className="gallery__wrapper">
                    <img key={index} src={`/${item}`} alt="image" />
                  </div>
                ))}
              </div>

              <button className="gallery__button" onClick={openGallery}>
                <img src={GalleryIcon} alt="image" />
                <p>Galeria de fotos</p>
              </button>

            </section>

            <Divider />

            <section className="arch">
              <div className="arch__text">
                <h1>Arquitetura</h1>
                <h2>{project.arch_title}</h2>

                <p>{project.arch_text}</p>
              </div>


              <div className="arch__image">
                <img src={`/${project.arch_image}`} alt="image" />
              </div>
            </section>
            <Divider />


            <section className="datasheet">

              <div className="datasheet__details">
                <h1>DIFERENCIAIS E FICHA TÉCNICA</h1>

                <ul>
                  {project.datasheet.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}

                </ul>
              </div>

              <div className="datasheet__image">
                <img src={`/${project.datasheet_image}`} alt="image" />
              </div>
            </section>


          </div>
          <Gallery images={project.gallery} />
          <AMozak />
          <Footer productId={project.id} />
        </>
      }

    </>

  );
}