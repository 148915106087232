import { useEffect } from 'react';
import Close from '../assets/images/close.png'
import acervoPopup from '../assets/images/popup_acervo.png'
import gsap from 'gsap';

import { FaArrowRight } from "react-icons/fa";

export default function Popup() {
  const scrollToContact = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }

  const handeClick = () => {
    scrollToContact();
    closePopUp();
  }

  function closePopUp() {
    document.querySelector("#popup").setAttribute("data-active", 'none')
  }


  useEffect(() => {
    gsap.fromTo('#popup', {
      opacity: 0
    }, {
      opacity: 1,
      delay: 1
    })
  })

  return (
    <>
      <div id='popup' data-active="active" className="bg-black/30 fixed top-0 left-0 w-full h-full z-50 hidden place-items-center  data-[active=active]:grid data-[active=none]:hidden " onClick={closePopUp}>
        <div className="flex flex-col justify-center w-[90%] md:w-[60%] h-[70%] bg-white p-2 md:p-4 items-center">
          <div className='relative flex flex-col w-full h-full items-center justify-between text-center p-2 drop-shadow-sm text-white rounded-sm'>
            <div className='bg-[#8ca8bc] w-full py-2 pt-3 flex flex-col items-center justify-center'>
                <h1 className='z-10 text-4xl font-bold drop-shadow-md'>ÚLTIMAS UNIDADES</h1>
                <h2 className='z-10 text-2xl font-light drop-shadow-md mb-3'>A partir de R$1.290.000</h2>
            </div>
            <div className='relative flex flex-col items-center justify-center w-full h-full overflow-hidden'>
                <p className='text-[#8ca8bc] text-2xl py-[10px] mb-[10px] text-center bg-white absolute -right-[10rem] top-6 md:-right-[7rem] md:top-8 w-[500px] rotate-[25deg] z-20 font-bold drop-shadow-sm'>75% VENDIDO</p>
                <img src='/VillaLogo.png' alt='Villa Logo' className='w-2/3 md:w-1/4 z-10 drop-shadow-md' /> 
                <p className='z-10 drop-shadow-md px-2 text-xl '>Condição exclusiva: 30% do valor apenas na entrega das chaves.</p>
                <a href='https://villamozak.com.br/' className='z-10 mb-3 mt-4 px-3 py-1 transition-all bg-white drop-shadow-md font-bold rounded-full hover:bg-black hover:text-white text-black flex hover:gap-2 gap-0 group items-center'>SAIBA MAIS<FaArrowRight className="w-0 transition-all group-hover:w-3 h-3" /></a>
                <div src='/popup/villa.webp' alt='Villa' className='absolute inset-0 bg-black/20 z-[2]' ></div>
                <img src='/popup/villa.webp' alt='Villa' className='w-full h-full absolute top-0 left-0 object-cover mb-3' />
            </div>
              <img id='close-popup' className="w-[12px] aspect-square object-contain absolute invert right-0 md:-right-8 -top-8 z-50 cursor-pointer" src={Close} onClick={closePopUp} alt="Fechar" />
            </div>
        </div>
      </div>
    </>
  );
}