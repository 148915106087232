import { React } from 'react';
import Card from './Card.js';
import { getBairros } from '../lib/localdata.jsx'


function BairrosCard({ projects }) {

    const bairros = getBairros()

    return (
        <section id="galeria" className='flex flex-col items-center justify-center w-full text-[#282828]'>
            <h1 className='sm:text-[3rem] text-[2rem] font-normal text-center mb-10'>Conheça nossos projetos</h1>
            <div className="container flex flex-col">
                {/* <div className='grid grid-cols-1 sm:grid-cols-2 grid-flow-row gap-10'> */}
                <div className='grid grid-cols-1 sm:flex flex-wrap gap-10'>
                    {bairros.map((bairro) => {
                        if (projects.filter((project) => project.location === bairro.bairro && project.sellpercent !== 100).length >= 1) {
                            return (<>
                                <div className="mx-4 sm:mx-0 border-[#5b6551] p-10 2xl:p-12 flex-1" id={bairro.bairro}>
                                    <h3 className='relative
                                        mt-2
                                        text-[2rem] font-extralight font-inter

                                    '>{bairro.bairro}</h3>
                                    <div className='min-h-14 mt-4 flex items-center justify-start'>
                                    <p className='xl:text-lg 2xl:text-lg font-thin text-balance'>{bairro.desc}</p>
                                    </div>
                                    <div className="flex sm:flex-nowrap flex-wrap justify-center sm:justify-start w-full gap-10 mt-8 ">
                                        {projects.map((project, index) => {
                                            return project.location === bairro.bairro && project.sellpercent !== 100 ?
                                                <Card project={project} />
                                                : null
                                        }
                                        )}
                                    </div>
                                </div>
                            </>
                            )
                        }
                    })}
                </div>
                {
                    projects.filter((project) => project.sellpercent === 100).length >= 1 ?
                        <div className="border-[1px] border-[#282828] mx-4 sm:mx-0 p-10 2xl:p-12 mt-10">
                            <h3 className='relative flex flex-col text-[2rem] font-light font-inter
                            mt-2 ml-2
                                before:w-[2.5%] before:h-full before:border-t-2 before:border-[#282828]
                                before:absolute before:top-[0px] before:left-0

                                after:w-[1.5%] after:h-full after:border-b-2 after:border-[#282828]
                                after:absolute after:top-[0px] after:left-[6%]
                            '>Esgotado</h3>
                            <p className='text-lg font-thin mt-4'>Agradecemos a confiança de todos os clientes Mozak que constroem conosco um Rio inspirador</p>
                            <div className="container mx-auto flex flex-wrap justify-center w-full gap-8 mt-8">
                                {projects.map((project, index) =>
                                    project.sellpercent === 100 ?
                                        <Card project={project} />
                                        : null
                                )}
                            </div>
                        </div>
                        :
                        ''
                }
            </div>

        </section>
    );
}

export default BairrosCard;