import React from "react";
import '../assets/styles/components/Filter.sass'
import { useState, useEffect } from "react";
import { getLocalData } from "../lib/localdata";
import arrow from '../assets/images/Icons/caret-down.svg'
import findIco from '../assets/images/Icons/list-magnifying-glass.svg'

export default function Filter(props) {
  const [filtered, setFiltered] = useState([])
  // todo: Filtrar separadamente por cada categoria

  const setProjects = props.setProjects;


  const handleChange = (e) => {
    let searchRooms = []
    let searchLocation = []
    let searchSize = []

    document.querySelectorAll('input[type=checkbox][name=rooms]').forEach(checkbox => {
      if (checkbox.checked) {
        searchRooms.push(checkbox.value)
      }
    })

    document.querySelectorAll('input[type=checkbox][name=location]').forEach(checkbox => {
      if (checkbox.checked) {
        searchLocation.push(checkbox.value)
      }
    })

    document.querySelectorAll('input[type=checkbox][name=size]').forEach(checkbox => {
      if (checkbox.checked) {
        searchSize.push(checkbox.value)
      }
    })

    filter(searchRooms, searchLocation, searchSize)
  }

  const filterLocation = (projects, search) => {

    if (search.length === 0) {
      return projects
    }
    let filteredProjects = []
    search.forEach(item => {
      projects.forEach(project => {
        if (project.location.includes(item)) {
          filteredProjects.push(project)
        }
      })
    })
    return filteredProjects
  }
  const filterSize = (projects, search) => {
    if (search.length === 0) {
      return projects
    }
    let filteredProjects = []
    search.forEach(item => {
      projects.forEach(project => {
        if (project.size.includes(item)) {
          filteredProjects.push(project)
        }
      })
    })

    return filteredProjects
  }

  const filterRooms = (projects, search) => {
    if (search.length === 0) {
      return projects
    }
    let filteredProjects = []
    search.forEach(item => {
      projects.forEach(project => {
        if (project.rooms.includes(item)) {
          filteredProjects.push(project)
        }
      })
    })
    return filteredProjects
  }

  const filter = (searchRooms, searchLocation, searchSize) => {
    let projects = getLocalData().projects
    let filteredRooms = []
    let filteredLocation = []
    let filteredSize = []
    filteredRooms = filterRooms(projects, searchRooms)

    filteredLocation = filterLocation(filteredRooms, searchLocation)

    filteredSize = filterSize(filteredLocation, searchSize)
    setProjects(filteredSize)
  }

  function showOptions(target, active) {
    if (active) {
      target.getElementsByClassName('options')[0].classList.add('showed')
      target.getElementsByClassName('arrow')[0].classList.add('active')
    } else {
      target.getElementsByClassName('options')[0].classList.remove('showed')
      target.getElementsByClassName('arrow')[0].classList.remove('active')
    }
  }


  function scrollToLocation(bairroId) {
    const locationElement = document.getElementById(bairroId);
    if (locationElement) {
      locationElement.scrollIntoView({ behavior: "smooth" });
    }
  }


  return (
    <div className="filter">
      <div className="body">
        <div className="filtros" defaultActiveKey="0">
          <img className="find-ico" src={findIco} alt="buscar empreendimentos" />
          <div className="filter-option" eventKey="0" >
            <p>TIPOLOGIA</p>
            <img className="arrow" src={arrow} alt="drop down field" />
            <div className="options">
              <div className="checkbox__wrapper">
                <input type="checkbox" id="tipologia_1" name="rooms" value="Estúdio" onChange={handleChange} />
                <label for="tipologia_1">Estúdio</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="tipologia_2" name="rooms" value="1 quarto" onChange={handleChange} />
                <label for="tipologia_2">1 quarto</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="tipologia_3" name="rooms" value="2 quartos" onChange={handleChange} />
                <label for="tipologia_3">2 quartos</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="tipologia_4" name="rooms" value="Double Suites" onChange={handleChange} />
                <label for="tipologia_4">Double Suites</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="tipologia_5" name="rooms" value="3 quartos" onChange={handleChange} />
                <label for="tipologia_5">3 quartos</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="tipologia_6" name="rooms" value="Cobertura" onChange={handleChange} />
                <label for="tipologia_6">Cobertura</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="tipologia_7" name="rooms" value="Triplex" onChange={handleChange} />
                <label for="tipologia_7">Triplex</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="tipologia_8" name="rooms" value="Loja" onChange={handleChange} />
                <label for="tipologia_8">Loja</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="tipologia_9" name="rooms" value="Sala Comercial" onChange={handleChange} />
                <label for="tipologia_9">Sala Comercial</label>
              </div>
            </div>
          </div>

          <div className="filter-option" eventKey="1" >
            <p>BAIRRO</p>
            <img className="arrow" src={arrow} alt="drop down field" />
            <div className="options">
              <div className="checkbox__wrapper">
                <input type="checkbox" id="bairro_1" name="location" value="Leblon" onChange={handleChange} />
                <label for="bairro_1">Leblon</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="bairro_2" name="location" value="Ipanema" onChange={handleChange} />
                <label for="bairro_2">Ipanema</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="bairro_3" name="location" value="Jardim Botânico" onChange={handleChange} />
                <label for="bairro_3">Jardim Botânico</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="bairro_4" name="location" value="Botafogo" onChange={handleChange} />
                <label for="bairro_4">Botafogo</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="bairro_5" name="location" value="Lagoa" onChange={handleChange} />
                <label for="bairro_5">Lagoa</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="bairro_6" name="location" value="Gávea" onChange={handleChange} />
                <label for="bairro_6">Gávea</label>
              </div>

            </div>
          </div>

          <div className="filter-option" eventKey="2" >
            <p>METRAGEM</p>
            <img className="arrow" src={arrow} alt="drop down field" />
            <div className="options">

              <div className="checkbox__wrapper">
                <input type="checkbox" id="metragem_1" name="size" value="De 10 a 30m²" onChange={handleChange} />
                <label for="metragem_1">De 10 a 30m²</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="metragem_2" name="size" value="De 30 a 60m²" onChange={handleChange} />
                <label for="metragem_2">De 30 a 60m²</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="metragem_3" name="size" value="De 60m2 a 90m²" onChange={handleChange} />
                <label for="metragem_3">De 60m2 a 90m²</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="metragem_4" name="size" value="De 90 a 120m²" onChange={handleChange} />
                <label for="metragem_4">De 90 a 120m²</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="metragem_5" name="size" value="De 120 a 150m²" onChange={handleChange} />
                <label for="metragem_5">De 120 a 150m²</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="metragem_6" name="size" value="De 150 a 180m²" onChange={handleChange} />
                <label for="metragem_6">De 150 a 180m²</label>
              </div>
              <div className="checkbox__wrapper">
                <input type="checkbox" id="metragem_7" name="size" value="De 180 a 600m²" onChange={handleChange} />
                <label for="metragem_7">De 180 a 600m²</label>
              </div>
            </div>
          </div>
          <button className="button-search" href="#" onClick={() => scrollToLocation('galeria')}>Buscar</button>

        </div>
      </div>
    </div>
  )
}