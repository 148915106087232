import Logo from '../assets/images/Mozak30Anos.png';
import Bsd from '../assets/images/bsd.png';
import Divider from '../components/Divider';
import '../assets/styles/components/Nav.sass';

export default function Nav(){
  return (
    <div className="nav__wrapper">
      <nav className="nav">
        
        <a href="/">
          <img src={Logo} className="logo" alt="image" />
        </a>

        <img src={Bsd} className="bsd" alt="image" />
      </nav>
      <Divider />
    </div>
    
  );
}
