'use client'
import './assets/styles/Home.sass';
import Divider from './components/Divider';
import Card from './components/Card';
import Footer from './components/Footer';
import Nav from './components/Nav';
import Button from './components/Button';
import Home from './views/Home';
import Project from './views/Project';
import { getLocalData } from './lib/localdata';
import DocumentMeta from "react-document-meta";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Favicon from "react-favicon";
import NewHome from './views/NewHome';





export default function App() {
  const meta = {
    title: 'Acervo - Mozak',
    description: 'Acervo - Mozak',
    // canonical: 'http://example.com/path/to/page',
    meta: {
      title: 'Acervo - Mozak',
      charset: 'utf-8',
      name: {
        keywords: 'mozak,leblon,empreendimento,lançamento,comercial,luxo,imobiliária,imóveis,imobiliárias,imobiliárias no rio de janeiro,imóveis no rio de janeiro,imóveis no rio,imóvel'
      }
    }
  };

  const data = getLocalData();

  return (
    <div>

      <Favicon url="/favicon.ico" />
      <DocumentMeta {...meta} />
      <Router basename='/'>
        <Routes>
          <Route exact path="/" element={<NewHome />} />
          <Route path="/projetos/:slug" element={<Project />} />
        </Routes>
      </Router>
    </div>

  );
}
